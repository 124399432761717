import React from 'react'
import { Container, Form as F } from 'react-bootstrap'
import Form from '~components/Form'
import Icon from '~components/Icon'

import CalculatorResult from '../CalculatorResult'
import Slider from '../Slider'

import useCalculatorSliders from './utils/useCalculatorSliders'
import useCalculateSavings from './utils/useCalculateSavings'

import * as s from './Calculator.module.scss'

const Calculator = ({
  heading,
  areas,
  sliders,
  blockResults,
  savingsCoefficients,
  calculatorForm,
  formMessages,
  emailReport,
}) => {
  const {
    selectedAreaIndex,
    sliderValues,
    handleChangeSelectedArea,
    handleChangeSlider,
    referenceCostPerSqM,
  } = useCalculatorSliders(sliders, areas)

  const { totalSavings, totalSavingsPercent, extraFormData } =
    useCalculateSavings({
      sliderValues,
      areas,
      selectedAreaIndex,
      savingsCoefficients,
    })

  const formId = calculatorForm?.formId
  const fetchedFields = calculatorForm?.fetchedFields

  return (
    <Container as="section" id="calculator" className={s.calculator}>
      <div className={s.calculator_inner}>
        <h2 className={s.calculator_title}>{heading}</h2>
        <F id={formId} className={s.calculator_saving}>
          <F.Group className={s.calculator_areas}>
            {areas.map((area, i) => (
              <F.Check key={area.name} type="radio" id={area.name}>
                <F.Check.Input
                  checked={selectedAreaIndex === i}
                  name="area-radio"
                  type="radio"
                  onChange={() => handleChangeSelectedArea(i)}
                />
                <F.Check.Label>
                  <div className="form-check_icon">
                    <Icon name={area.iconName} size={120} />
                  </div>
                  <p className="form-check_text">{area.name}</p>
                </F.Check.Label>
              </F.Check>
            ))}
          </F.Group>
          <F.Group className={s.calculator_sliders}>
            {sliders.map(({ id, defaultValue, ...sliderProps }) => (
              <Slider
                {...sliderProps}
                onChange={(e) => handleChangeSlider(e, id, referenceCostPerSqM)}
                key={sliderProps.label}
                value={sliderValues[id]}
              />
            ))}
          </F.Group>
          <CalculatorResult
            totalSavings={totalSavings}
            totalSavingsPercent={totalSavingsPercent}
            {...blockResults}
          />
        </F>
        <div className={s.calculator_cta}>
          <p className={s.calculator_titleForm}>{emailReport?.heading}</p>
          <Form
            variant="calc"
            formId={formId}
            data={fetchedFields}
            successMessage={formMessages?.success}
            errorMessage={formMessages?.error}
            extraFormData={extraFormData}
          />
        </div>
      </div>
    </Container>
  )
}

export default Calculator
