import { graphql } from 'gatsby'
import React from 'react'

import Home from '~containers/Home'

const IndexPage = ({ data }) => (
  <Home
    seo={data.wpPage.seo}
    {...data.wpPage.homepage}
    {...data.wpPage.common}
    reviews={data.reviews}
    header={data.header}
    headerMobileExtras={data.headerMobileExtras}
    footer={data.footer}
    socials={data.socials}
    cellar={data.cellar}
    contactInfo={data.contactInfo}
    cookiesOffcanvas={data.cookiesOffcanvas}
    bookingForm={data.bookingForm}
    bookADemo={data.bookADemo}
    formMessages={data.formMessages}
    calculatorForm={data.calculatorForm}
    sectionContactForm={data.sectionContactForm}
    contactUsForm={data.contactUsForm}
  />
)

export const query = graphql`
  query Homepage(
    $homepageSlug: String
    $code: String
    $headerSlug: String
    $headerMobileExtrasSlug: String
    $footerSlug: String
    $socialSlug: String
    $cellarSlug: String
    $cookiesOffcanvasSlug: String
    $bookingFormSlug: String
    $bookADemoSlug: String
    $formMessagesSlug: String
    $calculatorFormSlug: String
    $contactUsFormSlug: String
    $sectionContactFormSlug: String
  ) {
    header: wpMenu(slug: { eq: $headerSlug }) {
      headerExtras {
        headerButtonPrimary {
          text
          url
        }
      }
      menuItems {
        nodes {
          parentId
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
          navigationHeader {
            headerNavIsDesktopOnly
            headerNavSubMenuTitle
            headerNavDescription
          }
          childItems {
            nodes {
              label
              url
              navigationMenu {
                navTarget
                navRel
                navIsNotLocalizable
              }
              navigationHeader {
                headerNavIconName
                headerNavDescription
              }
            }
          }
        }
      }
    }

    headerMobileExtras: wpMenu(slug: { eq: $headerMobileExtrasSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
        }
      }
    }

    footer: wpMenu(slug: { eq: $footerSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
            navIsNotLocalizable
          }
        }
      }
    }
    socials: wpMenu(slug: { eq: $socialSlug }) {
      menuItems {
        nodes {
          label
          url
          navigationMenu {
            navRel
            navTarget
          }
          navigationSocial {
            navIconName
          }
        }
      }
    }
    cellar: wpMenu(slug: { eq: $cellarSlug }) {
      menuItems {
        nodes {
          label
          url
        }
      }
      cellarExtras {
        languageLink {
          text
          url
          target
          rel
          isNotLocalizable
        }
      }
    }
    contactInfo: wpComponent(slug: { eq: "contact-info" }) {
      contactInfo {
        contactInfo {
          address
          email
          phone
        }
      }
    }

    cookiesOffcanvas: wpComponent(slug: { eq: $cookiesOffcanvasSlug }) {
      cookiesOffcanvas {
        cookiesOffcanvas: cookiesoffcanvas {
          heading
          text
          buttonSecondary {
            text
            url
          }
          buttonPrimary {
            text
          }
        }
      }
    }

    sectionContactForm: wpComponent(slug: { eq: $sectionContactFormSlug }) {
      sectionContactForm {
        sectionContactForm {
          address
          email
          heading
          phone
          text
        }
      }
    }

    bookingForm: wpForm(id: { eq: $bookingFormSlug }) {
      formId
      fetchedFields {
        fieldId
        label
        type
        required
      }
    }
    bookADemo: wpComponent(slug: { eq: $bookADemoSlug }) {
      bookADemo {
        bookADemo {
          heading
          text
        }
      }
    }
    formMessages: wpComponent(slug: { eq: $formMessagesSlug }) {
      formMessages {
        formMessages {
          bookADemo {
            success
            error
          }
          sendReport {
            success
            error
          }
          contactUs {
            success
            error
          }
        }
      }
    }

    wpPage(slug: { eq: $homepageSlug }) {
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
        opengraphTitle
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
      homepage {
        sectionHeroHomepage {
          heading
          mediaLaptop {
            placeholder {
              gatsbyImage(
                quality: 90
                width: 1034
                height: 600
                placeholder: NONE
                fit: COVER
              )
            }
            videoUrl
          }
          mediaPhone {
            placeholder {
              gatsbyImage(
                quality: 90
                width: 235
                height: 510
                placeholder: NONE
                fit: COVER
              )
            }
            videoUrl
          }
          navigationButtons {
            buttonPrimary {
              text
            }
            buttonSecondary {
              text
            }
          }
          videoShortcut {
            text
            thumbnail {
              gatsbyImage(
                quality: 90
                width: 120
                height: 80
                placeholder: NONE
              )
            }
          }
          storeLinks {
            linkAppstore
          }
          storeImage {
            gatsbyImage(quality: 90, width: 180, height: 52, placeholder: NONE)
          }
        }
        sectionProblem {
          heading
          text
        }
        sectionSolution {
          heading
          text
        }
        sectionFeatures {
          heading
          cards {
            heading
            text
            link
          }
        }
        sectionVideo {
          video {
            url
          }
          fallback {
            url
          }
          pc {
            url
          }
        }
        sectionCalculator {
          heading
          areas {
            name
            deliveryCoefficients {
              main
              basement
            }
            iconName
            referenceCostPerSqM
          }
          sliders {
            label
            id
            min
            max
            step
            defaultValue
            unit
          }
          savingsCoefficients {
            savingsBooking {
              coefficient
              hourlyWage
              timePerDelivery
            }
            savingsWasteManagement {
              coefficient
              hourlyWage
              timePerDelivery
            }
          }
          blockResults {
            absolute {
              textBefore
              unit
              textAfter
            }
            representationRelative {
              textBefore
            }
          }
        }
        emailReport: sectionEmailReport {
          heading
        }
      }
      common {
        sectionFeaturesQuad {
          heading
          cards {
            heading
            text
            link
          }
          columns
        }
        sectionReviews: sectionTestimonials {
          heading
        }
        sectionPartners: sectionClients {
          heading
        }
      }
    }

    reviews: allWpTestimonial(
      filter: { testimonial: { code: { eq: $code } } }
      sort: { order: ASC, fields: date }
    ) {
      nodes {
        review: testimonial {
          author {
            name
            occupation
            company
            location
            avatarImage {
              gatsbyImage(quality: 90, width: 96, height: 96, placeholder: NONE)
            }
          }
          testimonialText
        }
      }
    }

    contactUsForm: wpForm(id: { eq: $contactUsFormSlug }) {
      formId
      fetchedFields {
        fieldId
        label
        type
        required
      }
    }

    calculatorForm: wpForm(id: { eq: $calculatorFormSlug }) {
      formId
      fetchedFields {
        fieldId
        label
        type
        required
      }
    }
  }
`

export default IndexPage
