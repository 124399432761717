// extracted by mini-css-extract-plugin
export var hero = "_1t_k2";
export var hero_appstore = "_1t_bX2";
export var hero_btn = "_1t_bR2";
export var hero_buttons = "_1t_bQ2";
export var hero_content = "_1t_p2";
export var hero_inner = "_1t_n2";
export var hero_link = "_1t_bS2";
export var hero_pic = "_1t_s2";
export var hero_pic_item = "_1t_bY2";
export var hero_pic_item__laptop = "_1t_b02";
export var hero_pic_item__mobile = "_1t_bZ2";
export var hero_video = "_1t_bT2";
export var hero_video_prev = "_1t_bV2";
export var hero_video_title = "_1t_bW2";
export var video = "_1t_v2";
export var videoWrapper = "_1t_t2";