// extracted by mini-css-extract-plugin
export var calculator = "_1s_bD2";
export var calculator_areas = "_1s_bM2";
export var calculator_container = "_1s_bK2";
export var calculator_cta = "_1s_bH2";
export var calculator_inner = "_1s_bF2";
export var calculator_saving = "_1s_bP2";
export var calculator_sliders = "_1s_bN2";
export var calculator_submit = "_1s_bL2";
export var calculator_title = "_1s_bG2";
export var calculator_titleForm = "_1s_bJ2";