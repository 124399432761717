import React from 'react'
import { Ratio } from 'react-bootstrap'
import cn from 'classnames'

import { getVideoUrl } from './utils'
import * as s from './Video.module.scss'

import { getCloudinaryVideoLink } from '~utils'
import { CLOUDINARY_PREFIX, CLOUDINARY_FORMAT } from '~utils/constants'
import useMatchMedia from '~hooks/useMatchMedia'


const Video = ({ video, fallback,pc, className }) => {

  const laptopVidWebm = getCloudinaryVideoLink(
    getVideoUrl(fallback),
    CLOUDINARY_PREFIX.LAPTOP,
    CLOUDINARY_FORMAT.WEBM
  )
  
  const laptopVidMp4 = getCloudinaryVideoLink(
    getVideoUrl(video),
    CLOUDINARY_PREFIX.LAPTOP,
    CLOUDINARY_FORMAT.MP4
  )
  
  const phoneVidWebm = getCloudinaryVideoLink(
    getVideoUrl(fallback),
    CLOUDINARY_PREFIX.PHONE_LANDSCAPE,
    CLOUDINARY_FORMAT.WEBM
  )
  
  const phoneVidMp4 = getCloudinaryVideoLink(
    getVideoUrl(video),
    CLOUDINARY_PREFIX.PHONE_LANDSCAPE,
    CLOUDINARY_FORMAT.MP4
  )

  
  const pcVidWebm = getCloudinaryVideoLink(
    getVideoUrl(pc),
    CLOUDINARY_PREFIX.PC,
    CLOUDINARY_FORMAT.WEBM
  )
  
  const pcVidMp4 = getCloudinaryVideoLink(
    getVideoUrl(pc),
    CLOUDINARY_PREFIX.PC,
    CLOUDINARY_FORMAT.MP4
  )

  const isMobile = useMatchMedia('(max-width: 950px)')
  const isLaptop = useMatchMedia('(max-width: 1124px)')

  const displayMobileVideo = phoneVidWebm && isMobile
  const displayLaptopVideo = laptopVidWebm && !isMobile && isLaptop
  const displayPcVideo = pcVidWebm && !isMobile && !isLaptop

  return(
  <section className={cn(s.section, className)}>
    <Ratio aspectRatio="16x9" className={s.aspectRationContainer}>
      <div className={s.wrapper}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        {displayPcVideo &&(
        <video controls id="vid" playsInline width="auto" height="100%">
        <source src={pcVidWebm} type="video/webm" />
        <source src={pcVidMp4} type="video/mp4" />
        </video>
        )}
        {displayLaptopVideo &&(
        <video controls id="vid" playsInline width="auto" height="100%">
        <source src={laptopVidWebm} type="video/webm" />
        <source src={laptopVidMp4} type="video/mp4" />
        </video>
        )}
        {displayMobileVideo &&(
        <video controls id="vid" playsInline width="auto" height="100%">
        <source src={phoneVidWebm} type="video/webm" />
        <source src={phoneVidMp4} type="video/mp4" />
        </video>
        )}
      </div>
    </Ratio>
  </section>)
}

export default Video